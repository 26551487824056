/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 */

	/**
	 * Call to mark terms accepted by user
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.acceptTerms()
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.acceptTerms()
	 *
	 * returns: void
	 */

	export const acceptTerms = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/acceptTerms';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-authentications-svc',
			action: 'acceptTerms'
		});
	};


	/**
	 * Call to authenticate using DUO, returns string CODE:XXXX, append XXXX to challenge token and build new auth string
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.duo()
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.duo()
	 *
	 * authorization: string
	 * passcode: string
	 * factor: string
	 * user: string
	 * returns: string
	 */

	export const duo = ({authorization, passcode, factor, user, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/duo';

		const params = {
			passcode,
			factor,
			user
		};

		const headers = {
			'xe-xxsid': xxsid,
			'Authorization': authorization
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-authentications-svc',
			action: 'duo'
		});
	};


	/**
	 * Call to get the challenge string to build auth string with
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.getChallenge()
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.getChallenge()
	 *
	 * LoginName: string
	 * Nonce: string
	 * returns: ChallengeResponse
	 */

	export const getChallenge = ({LoginName, Nonce, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/getChallenge';

		const params = {
			LoginName,
			Nonce
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-authentications-svc',
			action: 'getChallenge'
		});
	};


	/**
	 * Call to check if terms are accepted, returns null if accepted otherwise returns text for terms. see getChallenge as it returns this same information
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.getCheckTerms()
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.getCheckTerms()
	 *
	 * returns: string
	 */

	export const getCheckTerms = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/checkTerms';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-authentications-svc',
			action: 'getCheckTerms'
		});
	};


	/**
	 * Call to login user
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.login()
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.login()
	 *
	 * authorization: string
	 * xeNew: string
	 * xeNewLogin: string
	 * xeExternal: string
	 * xeAcceptTerms: boolean
	 * returns: string
	 */

	export const login = ({authorization, xeNew, xeNewLogin, xeExternal, xeAcceptTerms, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/login';

		const params = {
			'xe-accept-terms': xeAcceptTerms
		};

		const headers = {
			'xe-xxsid': xxsid,
			'Authorization': authorization,
			'xe-new': xeNew,
			'xe-new-login': xeNewLogin,
			'xe-external': xeExternal
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-authentications-svc',
			action: 'login'
		});
	};


	/**
	 * Call to logout user, this removes the challenge token and invalids user auth
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.logout()
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.logout()
	 *
	 * returns: void
	 */

	export const logout = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/logout';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-authentications-svc',
			action: 'logout'
		});
	};


	/**
	 * User to keep server session alive
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.ping()
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.ping()
	 *
	 * authorization: string
	 * returns: string
	 */

	export const ping = ({authorization, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/ping';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid,
			'Authorization': authorization
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-authentications-svc',
			action: 'ping'
		});
	};


	/**
	 * Call to send user a reset password email/text
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.resetPassword()
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.resetPassword()
	 *
	 * LoginName: string
	 * returns: string
	 */

	export const resetPassword = ({LoginName, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/resetPassword';

		const params = {
			LoginName
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-authentications-svc',
			action: 'resetPassword'
		});
	};


	/**
	 * Call to authenticate using YUBIKEY, returns string CODE:XXXX, append XXXX to challenge token and build new auth string
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.yubikey()
	 * @see com.thrasys.xnet.jetty.AuthenticationApi.yubikey()
	 *
	 * authorization: string
	 * otp: string
	 * istrustedpc: boolean
	 * returns: string
	 */

	export const yubikey = ({authorization, otp, istrustedpc, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/yubikey';

		const params = {
			otp,
			istrustedpc
		};

		const headers = {
			'xe-xxsid': xxsid,
			'Authorization': authorization
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-authentications-svc',
			action: 'yubikey'
		});
	};

