/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Record/Create artifacts for a patient insurance authorization of services.
 */

	/**
	 * Add a care day to the Authorization
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doAddCareDay()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.addCareDay()
	 *
	 * xeAuthCCR: XeAuthCCR
	 * returns: XeAuthCCR
	 */
	import addCareDayRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';
	import addCareDayResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';

	export const addCareDay = (xeAuthCCR, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/careDay';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeAuthCCR;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'addCareDay',
			requestSchema: addCareDayRequestSchema,
			responseSchema: addCareDayResponseSchema
		});
	};

	addCareDay.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';
	addCareDay.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';

	/**
	 * Query the Authorizations using the matching criteria set in the request
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.browse()
	 *
	 * xeAuthBrowseRequest: XeAuthBrowseRequest
	 * returns: XeAuthBrowse
	 */
	import browseRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowseRequest.json';
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse.json';

	export const browse = (xeAuthBrowseRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/list';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeAuthBrowseRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'browse',
			requestSchema: browseRequestSchema,
			responseSchema: browseResponseSchema
		});
	};

	browse.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowseRequest.json';
	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse.json';

	/**
	 * Browse care days for the Authorization
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doBrowseCareDay()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.browseCareDay()
	 *
	 * ivid: number
	 * active: boolean
	 * returns: XeAuthCCR
	 */
	import browseCareDayResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';

	export const browseCareDay = ({ivid, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/careDay/list/' + ivid;

		const params = {
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'browseCareDay',
			responseSchema: browseCareDayResponseSchema
		});
	};

	browseCareDay.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';

	/**
	 * Check benefit coverage of services
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doCheckBenefit()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.checkBenefit()
	 *
	 * authCheckBenefitRequest: AuthCheckBenefitRequest
	 * returns: AuthCheckBenefitResponse
	 */
	import checkBenefitRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest.json';
	import checkBenefitResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse.json';

	export const checkBenefit = (authCheckBenefitRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/checkBenefit';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = authCheckBenefitRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'checkBenefit',
			requestSchema: checkBenefitRequestSchema,
			responseSchema: checkBenefitResponseSchema
		});
	};

	checkBenefit.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest.json';
	checkBenefit.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse.json';

	/**
	 * Check possible duplicate authorizations already exist
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doCheckDuplicate()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.checkDuplicate()
	 *
	 * authDetails: AuthDetails
	 * returns: XeAuthVisit
	 */
	import checkDuplicateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
	import checkDuplicateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.XeAuthVisit.json';

	export const checkDuplicate = (authDetails, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/checkDuplicate';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = authDetails;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'checkDuplicate',
			requestSchema: checkDuplicateRequestSchema,
			responseSchema: checkDuplicateResponseSchema
		});
	};

	checkDuplicate.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
	checkDuplicate.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthVisit.json';

	/**
	 * Create a copy of an existing authorization. Besides a simple copy, special options are available wiht optional flag parameters.}
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doCopy()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.copy()
	 *
	 * ivid: number
	 * xeAuthCopyRequest: XeAuthCopyRequest
	 * returns: AuthDetails
	 */
	import copyRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.XeAuthCopyRequest.json';
	import copyResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	export const copy = (xeAuthCopyRequest, {ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/copy/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeAuthCopyRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'copy',
			requestSchema: copyRequestSchema,
			responseSchema: copyResponseSchema
		});
	};

	copy.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthCopyRequest.json';
	copy.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	/**
	 * Create a new XeAuth record 
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doCreate()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.create()
	 *
	 * authDetails: AuthDetails
	 * returns: AuthResponse
	 */
	import createRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
	import createResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthResponse.json';

	export const create = (authDetails, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/create';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = authDetails;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'create',
			requestSchema: createRequestSchema,
			responseSchema: createResponseSchema
		});
	};

	create.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
	create.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthResponse.json';

	/**
	 * Delete a care day in an Authorization
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doDeleteCareDay()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.deleteCareDay()
	 *
	 * authReviewId: number
	 * returns: XeAuthCCR
	 */
	import deleteCareDayResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';

	export const deleteCareDay = ({authReviewId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/careDay/' + authReviewId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'DELETE',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'deleteCareDay',
			responseSchema: deleteCareDayResponseSchema
		});
	};

	deleteCareDay.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';

	/**
	 * Extend authorization's expiraation date.
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doExtendExpiration()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.extendExpiration()
	 *
	 * ivid: number
	 * authSimpleRequest: AuthSimpleRequest
	 * returns: AuthSimpleRequest
	 */
	import extendExpirationRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';
	import extendExpirationResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';

	export const extendExpiration = (authSimpleRequest, {ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/extendExpiration/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = authSimpleRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'extendExpiration',
			requestSchema: extendExpirationRequestSchema,
			responseSchema: extendExpirationResponseSchema
		});
	};

	extendExpiration.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';
	extendExpiration.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';

	/**
	 * Get the authorization responsibility status and description.
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetAuthResponsibility()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getAuthResponsibility()
	 *
	 * ivid: number
	 * startDate: Date
	 * authSubTypeId: string
	 * requestedPosid: number
	 * returns: AuthSimpleRequest
	 */
	import getAuthResponsibilityResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';

	export const getAuthResponsibility = ({ivid, startDate, authSubTypeId, requestedPosid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/authResponsibility/' + ivid;

		const params = {
			startDate,
			authSubTypeId,
			requestedPosid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getAuthResponsibility',
			responseSchema: getAuthResponsibilityResponseSchema
		});
	};

	getAuthResponsibility.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';

	/**
	 * Get decision ready status for the type of action (e.g., APPROVE, MODIFY, DENY or NONE)
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetDecisionStatus()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getDecisionStatus()
	 *
	 * ivid: number
	 * intendedDecision: string
	 * returns: AuthCheckDecisionReady
	 */
	import getDecisionStatusResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckDecisionReady.json';

	export const getDecisionStatus = ({ivid, intendedDecision, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/getDecisionStatus/' + ivid;

		const params = {
			intendedDecision
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getDecisionStatus',
			responseSchema: getDecisionStatusResponseSchema
		});
	};

	getDecisionStatus.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckDecisionReady.json';

	/**
	 * Returns the full details of the authorization
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetDetail()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getDetail()
	 *
	 * ivid: number
	 * returns: AuthDetails
	 */
	import getDetailResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	export const getDetail = ({ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/details/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getDetail',
			responseSchema: getDetailResponseSchema
		});
	};

	getDetail.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	/**
	 * Get DueDate and ComplianceDate
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetDueDates()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getDueDates()
	 *
	 * receivedDate: Date
	 * umUrgencyId: string
	 * healthPlanTypeId: string
	 * returns: XeAuthDueDates
	 */
	import getDueDatesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.XeAuthDueDates.json';

	export const getDueDates = ({receivedDate, umUrgencyId, healthPlanTypeId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/getDueDates';

		const params = {
			receivedDate,
			umUrgencyId,
			healthPlanTypeId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getDueDates',
			responseSchema: getDueDatesResponseSchema
		});
	};

	getDueDates.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthDueDates.json';

	/**
	 * Get potential duplicate authorizations already exist
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetDuplicate()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getDuplicate()
	 *
	 * ivid: number
	 * returns: XeAuthVisit
	 */
	import getDuplicateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.XeAuthVisit.json';

	export const getDuplicate = ({ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/getDuplicate/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getDuplicate',
			responseSchema: getDuplicateResponseSchema
		});
	};

	getDuplicate.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthVisit.json';

	/**
	 * Get available extension hours. In the response, if CanExtend is false, then this authorization cannot be extend. If the IsExtended is true, then already extended is the reason for cannot extend anymore. The ExtensionHours is the number of hours the auth can be extended for. If it is 0, the authorization cannot be extended by policy. The DueDate and ComplianceDate are the corresponding date-time values that would be after the extension, if the auth can be extended.
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetExtensionHours()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getExtensionHours()
	 *
	 * ivid: number
	 * returns: XeAuthDueDates
	 */
	import getExtensionHoursResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.XeAuthDueDates.json';

	export const getExtensionHours = ({ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/getExtensionHours/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getExtensionHours',
			responseSchema: getExtensionHoursResponseSchema
		});
	};

	getExtensionHours.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthDueDates.json';

	/**
	 * Get a template for adding new auth. Sets some default values in the template based on things like the user's enterprise type
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetFromSpec()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getFromSpec()
	 *
	 * ipid: number
	 * returns: AuthDetails
	 */
	import getFromSpecResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	export const getFromSpec = ({ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/spec';

		const params = {
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getFromSpec',
			responseSchema: getFromSpecResponseSchema
		});
	};

	getFromSpec.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	/**
	 * Get other auths of the the same member that has the given auth, with indication if the auth is a duplicate of the given one.
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetMemberAuthWithDupIndication()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getMemberAuthWithDupIndication()
	 *
	 * ivid: number
	 * startDate: Date
	 * endDate: Date
	 * returns: XeAuthBrowseWithDupToAuthIVID
	 */
	import getMemberAuthWithDupIndicationResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowseWithDupToAuthIVID.json';

	export const getMemberAuthWithDupIndication = ({ivid, startDate, endDate, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/getMemberAuthWithDupIndication/' + ivid;

		const params = {
			startDate,
			endDate
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getMemberAuthWithDupIndication',
			responseSchema: getMemberAuthWithDupIndicationResponseSchema
		});
	};

	getMemberAuthWithDupIndication.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowseWithDupToAuthIVID.json';

	/**
	 * Get a validated service to add to an auth
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetServiceOrder()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getServiceOrder()
	 *
	 * ivid: number
	 * serviceId: number
	 * code: string
	 * codeTypeId: string
	 * patientPlanId: string
	 * serviceStartDate: Date
	 * serviceEndDate: Date
	 * groupDetails: boolean
	 * pointOfServiceId: number
	 * preferredProvider: number
	 * returns: AuthDetails
	 */
	import getServiceOrderResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	export const getServiceOrder = ({ivid, serviceId, code, codeTypeId, patientPlanId, serviceStartDate, serviceEndDate, groupDetails, pointOfServiceId, preferredProvider, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/serviceOrder';

		const params = {
			ivid,
			serviceId,
			code,
			codeTypeId,
			patientPlanId,
			serviceStartDate,
			serviceEndDate,
			groupDetails,
			pointOfServiceId,
			preferredProvider
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getServiceOrder',
			responseSchema: getServiceOrderResponseSchema
		});
	};

	getServiceOrder.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	/**
	 * Get the Episode Summary as File for the given IVID
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetStyledEpisodeSummaryFile()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getStyledEpisodeSummaryFile()
	 *
	 * ivid: number
	 * returns: XeFileStorage
	 */
	import getStyledEpisodeSummaryFileResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeFileStorage.json';

	export const getStyledEpisodeSummaryFile = ({ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/styledEpisodeSummaryFile/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getStyledEpisodeSummaryFile',
			responseSchema: getStyledEpisodeSummaryFileResponseSchema
		});
	};

	getStyledEpisodeSummaryFile.responseSchema = 'com.thrasys.xnet.xmlobjects.XeFileStorage.json';

	/**
	 * Get a validated visit diagnosis
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doGetVisitDiagnosis()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.getVisitDiagnosis()
	 *
	 * ivid: number
	 * ipid: number
	 * code: string
	 * codeTypeId: string
	 * onsetDate: Date
	 * diagnosisId: string
	 * returns: XeVisitDiagnosis
	 */
	import getVisitDiagnosisResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails$XeVisitDiagnosis.json';

	export const getVisitDiagnosis = ({ivid, ipid, code, codeTypeId, onsetDate, diagnosisId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/visitDiagnosis';

		const params = {
			ivid,
			ipid,
			code,
			codeTypeId,
			onsetDate,
			diagnosisId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'getVisitDiagnosis',
			responseSchema: getVisitDiagnosisResponseSchema
		});
	};

	getVisitDiagnosis.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails$XeVisitDiagnosis.json';

	/**
	 * Mark an Auth in Error
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.markInError()
	 *
	 * ivid: number
	 * authSimpleRequest: AuthSimpleRequest
	 * returns: AssetXeAuth
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeAuth.json';

	export const markInError = (authSimpleRequest, {ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/markInError/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = authSimpleRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';
	markInError.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeAuth.json';

	/**
	 * Queries the Auth History for the given IVID
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doQueryHistory()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.queryHistory()
	 *
	 * ivid: number
	 * activityAsset: string[]
	 * returns: XeHistoryView
	 */
	import queryHistoryResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.historyview.XeHistoryView.json';

	export const queryHistory = ({ivid, activityAsset, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/history/' + ivid;

		const params = {
			activityAsset
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'queryHistory',
			responseSchema: queryHistoryResponseSchema
		});
	};

	queryHistory.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.historyview.XeHistoryView.json';

	/**
	 * Queries the States with possible filters: {WorkID, HealthPlanTypeID, IsInPatient, ReceivedDate}
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doQueryWithState()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.queryWithState()
	 *
	 * startDate: Date
	 * endDate: Date
	 * workId: string
	 * healthPlanTypeId: string
	 * isInPatient: boolean
	 * returns: AuthSimpleResponse
	 */
	import queryWithStateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse.json';

	export const queryWithState = ({startDate, endDate, workId, healthPlanTypeId, isInPatient, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/queryWithState';

		const params = {
			startDate,
			endDate,
			workId,
			healthPlanTypeId,
			isInPatient
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'queryWithState',
			responseSchema: queryWithStateResponseSchema
		});
	};

	queryWithState.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse.json';

	/**
	 * Create or update a draft of an authorization request as a XeAuth record
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doSave()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.save()
	 *
	 * authDetails: AuthDetails
	 * returns: AuthDetails
	 */
	import saveRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
	import saveResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	export const save = (authDetails, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/save';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = authDetails;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'save',
			requestSchema: saveRequestSchema,
			responseSchema: saveResponseSchema
		});
	};

	save.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
	save.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	/**
	 * Send Authorization as New to OutboundQueue. Either IVID (or) AuthNum is required
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doSendAuthAsNew()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.sendAuthAsNew()
	 *
	 * ivid: number
	 * authNum: string
	 * returns: AuthOutboundResponse
	 */
	import sendAuthAsNewResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthOutboundResponse.json';

	export const sendAuthAsNew = ({ivid, authNum, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/export/authAsNew';

		const params = {
			ivid,
			authNum
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'sendAuthAsNew',
			responseSchema: sendAuthAsNewResponseSchema
		});
	};

	sendAuthAsNew.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthOutboundResponse.json';

	/**
	 * Send Authorization as Update to OutboundQueue. Either IVID (or) AuthNum is required
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doSendAuthAsUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.sendAuthAsUpdate()
	 *
	 * ivid: number
	 * authNum: string
	 * returns: AuthOutboundResponse
	 */
	import sendAuthAsUpdateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthOutboundResponse.json';

	export const sendAuthAsUpdate = ({ivid, authNum, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/export/authAsUpdate';

		const params = {
			ivid,
			authNum
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'sendAuthAsUpdate',
			responseSchema: sendAuthAsUpdateResponseSchema
		});
	};

	sendAuthAsUpdate.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthOutboundResponse.json';

	/**
	 * Send Authorization in Batch to OutboundQueue
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doSendAuthInBatch()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.sendAuthInBatch()
	 *
	 * status: string
	 * isInPatient: boolean
	 * returns: AuthSimpleResponse
	 */
	import sendAuthInBatchResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse.json';

	export const sendAuthInBatch = ({status, isInPatient, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/export/authInBatch';

		const params = {
			status,
			isInPatient
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'sendAuthInBatch',
			responseSchema: sendAuthInBatchResponseSchema
		});
	};

	sendAuthInBatch.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse.json';

	/**
	 * Send  the updates in Batch to OutboundQueue
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doSendUpdatesInBatch()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.sendUpdatesInBatch()
	 *
	 * returns: AuthOutboundResponse
	 */
	import sendUpdatesInBatchResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthOutboundResponse.json';

	export const sendUpdatesInBatch = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/export/updatesInBatch';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'sendUpdatesInBatch',
			responseSchema: sendUpdatesInBatchResponseSchema
		});
	};

	sendUpdatesInBatch.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthOutboundResponse.json';

	/**
	 * Set all services' authorized units to match the requested units to prepare for all approval.
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doSetAuthorizedAsRequested()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.setAuthorizedAsRequested()
	 *
	 * ivid: number
	 * authSimpleRequest: AuthSimpleRequest
	 * returns: AuthSimpleRequest
	 */
	import setAuthorizedAsRequestedRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';
	import setAuthorizedAsRequestedResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';

	export const setAuthorizedAsRequested = (authSimpleRequest, {ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/setAuthorizedAsRequested/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = authSimpleRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'setAuthorizedAsRequested',
			requestSchema: setAuthorizedAsRequestedRequestSchema,
			responseSchema: setAuthorizedAsRequestedResponseSchema
		});
	};

	setAuthorizedAsRequested.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';
	setAuthorizedAsRequested.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';

	/**
	 * Submit an existing authorization draft in DB to start work
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doSubmit()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.submit()
	 *
	 * ivid: number
	 * authDetails: AuthDetails
	 * returns: AuthDetails
	 */
	import submitRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
	import submitResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	export const submit = (authDetails, {ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/submit/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = authDetails;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'submit',
			requestSchema: submitRequestSchema,
			responseSchema: submitResponseSchema
		});
	};

	submit.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
	submit.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	/**
	 * Update the XeAuth with the given IVID
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.update()
	 *
	 * ivid: number
	 * authDetails: AuthDetails
	 * returns: AuthDetails
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	export const update = (authDetails, {ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = authDetails;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';
	update.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails.json';

	/**
	 * Update a care day in an Authorization
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doUpdateCareDay()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.updateCareDay()
	 *
	 * authReviewId: number
	 * xeAuthCCR: XeAuthCCR
	 * returns: XeAuthCCR
	 */
	import updateCareDayRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';
	import updateCareDayResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';

	export const updateCareDay = (xeAuthCCR, {authReviewId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/careDay/' + authReviewId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeAuthCCR;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-auths-svc',
			action: 'updateCareDay',
			requestSchema: updateCareDayRequestSchema,
			responseSchema: updateCareDayResponseSchema
		});
	};

	updateCareDay.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';
	updateCareDay.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.authreview.XeAuthCCR.json';

	/**
	 * Update the Queue Time of the Authorization
	 * @see com.thrasys.xnet.erp.managedcare.handler.AuthHandler.doUpdateQueueTime()
	 * @see com.thrasys.xnet.erp.resthandler.auth.AuthService.updateQueueTime()
	 *
	 * ivid: number
	 * authNum: string
	 * returns: AuthResponse
	 */
	import updateQueueTimeResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.auth.AuthResponse.json';

	export const updateQueueTime = ({ivid, authNum, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/auth/queueTime';

		const params = {
			ivid,
			authNum
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-auths-svc',
			action: 'updateQueueTime',
			responseSchema: updateQueueTimeResponseSchema
		});
	};

	updateQueueTime.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthResponse.json';
