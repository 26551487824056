/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Methods pertaining to catalogs of clinical documents for patients.
 */

	/**
	 * list of catalogs with assessmentID matching filter parameters
	 * @see com.thrasys.xnet.erp.clinical.handler.AssessmentCatalogHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.assessmentcatalog.AssessmentCatalogService.browse()
	 *
	 * searchText: string
	 * catalogId: number
	 * catalogTypeId: string
	 * catContentTypeId: string
	 * returns: XeAssessmentCatalog
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog.json';

	export const browse = ({searchText, catalogId, catalogTypeId, catContentTypeId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/clinDocCatalog/list';

		const params = {
			searchText,
			catalogId,
			catalogTypeId,
			catContentTypeId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-assessment-catalogs-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog.json';
